import React from "react"

const MailchimpForm = ({mode = "light"}) => {  
  return (
    <div className="mt-8 ml-auto mr-auto lg:mr-0 lg:ml-0 max-w-lg lg:w-auto">
      <p className={"font-body font-light text-base text-left lg:text-lg text-center lg:text-left" + (mode === "light" ? "opacity-90 text-white" : "text-black")}>
        Sign Up for our newsletter to get the latest news on BrickMark.
      </p>
      <div id="mc_embed_signup" className="mt-8">
        <form
          action="https://brickmark.us19.list-manage.com/subscribe/post?u=6cad834a76c2c4a7d2ff66e21&amp;id=ae83ee9ede"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <label
                htmlFor="mce-EMAIL"
                className="mt-16 font-body font-light text-base text-left opacity-90 text-white lg:text-lg"
              >
                {" "}
              </label>
              <div className="flex flex-row mt-2 ">
                <input
                  type="email"
                  name="EMAIL"
                  className="required email"
                  id="mce-EMAIL"
                  placeholder="Email Address"
                  className={"w-full h-12 border-solid border font-base pl-4 " + (mode === "light" ? "border-white bg-brickmark-blue text-white " : "bg-white border-black text-black")}
                />
                <input
                  type="submit"
                  value="Subscribe"
                  name="subscribe"
                  id="mc-embedded-subscribe"
                  className={"button h-12 pl-2 pr-2 " + (mode === "light" ? "bg-white text-brickmark-blue" : "bg-brickmark-blue text-white")}
                />
              </div>
              <p className={"text-sm opacity-50 mt-4 text-center lg:text-left " + (mode === "light" ? "text-white" : "")}>
                You can unsubscribe at any time by clicking the link in the footer
                of our emails. For information about our privacy practices, please
                visit our website.
              </p>
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              ></div>
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              ></div>
            </div>
            <div
              style={{ position: "absolute", left: -200000, ariaHidden: true }}
            >
              <input
                type="text"
                name="b_6cad834a76c2c4a7d2ff66e21_ae83ee9ede"
                tabIndex="-1"
                value=""
                onChange={() => {}}
              />
            </div>
            <div className="clear mt-2"></div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default MailchimpForm
